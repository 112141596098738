module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://editor.patrizialutz.tech/graphql","schema":{"requestConcurrency":6,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"perPage":100},"verbose":true,"debug":{"preview":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Patrizia Lutz - WordPress Developer","short_name":"Patrizia Lutz","description":"Web Developer specializing in WordPress, and free and open-source technology.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#0b4f6c","display":"browser","icon":"src/assets/img/sneaky-cat-transparent.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"111c4575756374174da130a7392f49f2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
